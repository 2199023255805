// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import {
  DATADOG_RUM_APP_ID,
  DATADOG_RUM_CLIENT_TOKEN,
  PROD,
} from '~/common/config/runtime';
import { env, service, version } from './datadog-logger';

const beforeSend: RumInitConfiguration['beforeSend'] = (event, context) => {
  // tag RUM events so they can be filtered where necessary
  event.context['isRUM'] = true;
  return true;
};

export const rumInit = () => {
  const applicationId = DATADOG_RUM_APP_ID;
  const clientToken = DATADOG_RUM_CLIENT_TOKEN;

  if (applicationId?.length > 0 && clientToken?.length > 0 && PROD)
    datadogRum.init({
      applicationId,
      clientToken,
      service,
      env,
      site: 'datadoghq.com',
      version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask',
      beforeSend,
    });
};
