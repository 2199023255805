// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { ChakraProvider, ColorMode, extendTheme } from '@chakra-ui/react';
import { theme as npTheme } from '@niphtio/np-theme';
import { FC, PropsWithChildren } from 'react';
import { CookieColorModeManager } from '../ColorModeManager/CookieColorModeManager';
import { FixedColorModeManager } from '../ColorModeManager/FixedColorModeManager';

interface Props extends PropsWithChildren {
  isColorModeSupported?: boolean;
  colorMode: ColorMode;
}

export const NpThemeProvider: FC<Props> = ({
  isColorModeSupported = false,
  colorMode,
  children,
}) => {
  const defaultColorMode = (npTheme as any).config.color ?? 'light';
  const colorModeOrDefault = colorMode ?? defaultColorMode;

  const theme = extendTheme(npTheme, {
    initialColorMode: defaultColorMode,
    useSystemColorMode: false,
  });

  const colorModeManager = isColorModeSupported
    ? CookieColorModeManager(colorMode ?? defaultColorMode)
    : FixedColorModeManager(colorModeOrDefault);

  return (
    <ChakraProvider theme={theme} colorModeManager={colorModeManager}>
      {children}
    </ChakraProvider>
  );
};
