// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

// Local Storage (LS)
// User Session Data (USD): should be deleted when the user logs out

// stores the id used to identify the browser.
export const LS_BROWSER_ID_STORAGE_KEY = 'BROWSER_ID';

// stores if the user dismissed the browser support warning
export const LS_USD_BROWSER_SUPPORT_WARNING_DIMISSED_KEY =
  'LS_USD_BROWSER_SUPPORT_WARNING_DIMISSED__KEY';

// stores if the user dismissed the pwa hint
// usd - user session data
export const LS_USD_INSTALL_PWA_HINT_DISMISSED_KEY =
  'LS_USD_INSTALL_PWA_HINT_DISMISSED_KEY';
