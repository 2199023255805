// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

// If a key is common across local storage, session storage, and cookie storage, it should be defined here.

// stores the signup utm campaign data
export const SIGNUP_UTM_KEY = 'SIGNUP_UTM_KEY';

// stores the signup referrer data
export const SIGNUP_REFERRER_KEY = 'SIGNUP_REFERRER_KEY';
