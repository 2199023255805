// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { SIGNUP_REFERRER_KEY, SIGNUP_UTM_KEY } from '~/common/commonKeys';

const __acceptedKeySet = new Set([
  '__storage_test__',
  SIGNUP_UTM_KEY,
  SIGNUP_REFERRER_KEY,
]);

/**
 * Provides a storage interface that uses cookies.
 * NOTE: It can only modify keys in the `acceptedKeySet`.
 */
export class CookieStorage implements Storage {
  private available: boolean;
  private acceptedKeySet: Set<string>;
  dictionary: Record<string, any> = {};
  get keys() {
    return Object.keys(this.dictionary);
  }
  get length() {
    return this.keys.length;
  }

  constructor(
    internal = false,
    acceptedKeySet: Set<string> = __acceptedKeySet,
  ) {
    this.acceptedKeySet = acceptedKeySet;
    this.available = internal ? false : this.storageAvailable();
  }

  private isKeyAccepted(key): boolean {
    return this.acceptedKeySet.has(key);
  }

  private storageAvailable() {
    try {
      const x = '__storage_test__';
      this.setCookie(x, x);
      if (this.getCookie(x) !== x) {
        throw 'Storage not available';
      }
      this.deleteCookie(x);
      return true;
    } catch (e) {
      return false;
    }
  }

  public clear() {
    if (this.available) {
      this.getKeys()
        .filter(this.isKeyAccepted)
        .forEach((key) => this.removeItem(key));
    }
    this.keys.map((key) => {
      delete this.dictionary[key];
    });
  }

  public getKeys(): string[] {
    if (this.available) {
      return document.cookie
        .split('; ')
        .map((c) => {
          const [name] = c.split('=');
          return decodeURIComponent(name);
        })
        .filter(Boolean);
    } else {
      return this.keys;
    }
  }
  public key(index: number): string | null {
    if (this.available) {
      return this.getKeys()[index];
    } else {
      return this.keys[index] ?? null;
    }
  }

  public removeItem(key: string): void {
    if (!this.isKeyAccepted(key)) return;
    if (this.available) {
      this.deleteCookie(key);
    } else {
      delete this.dictionary[key];
    }
  }

  public getItem(key: string) {
    if (!this.isKeyAccepted(key)) return;
    if (this.available) {
      return this.getCookie(key);
    } else {
      return this.dictionary[key] ?? null;
    }
  }

  public setItem(key: string, value: any) {
    if (!this.isKeyAccepted(key)) return;
    if (this.available) {
      this.setCookie(key, value);
    } else {
      this.dictionary[key] = value;
    }
  }

  private getCookie(name) {
    const cookies = document.cookie.split('; ');
    const cookie = cookies.find((c) =>
      c.startsWith(encodeURIComponent(name) + '='),
    );
    if (!cookie) return undefined;
    return decodeURIComponent(cookie.split('=')[1]);
  }

  private setCookie(
    name,
    value,
    additionalOptions: {
      path?: string;
      expires?: Date;
      domain?: string;
      secure?: boolean;
      sameSite?: string;
    } = {},
  ) {
    const options = { ...additionalOptions };
    let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

    if (options.path) {
      cookie += `; path=${options.path}`;
    }

    if (options.expires) {
      cookie += `; expires=${options.expires.toUTCString()}`;
    }

    if (options.domain) {
      cookie += `; domain=${options.domain}`;
    }

    if (options.secure) {
      cookie += '; secure';
    }

    if (options.sameSite) {
      cookie += `; samesite=${options.sameSite}`;
    }

    document.cookie = cookie;
  }

  private deleteCookie(name) {
    this.setCookie(name, '', { expires: new Date(0) });
  }
}
