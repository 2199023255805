// Copyright © 2022 Niphtio, Inc.
// All Rights Reserved.

import React, { PropsWithChildren } from 'react';
import { datadog } from '~/lib/datadog/datadog-logger';

/**
 * Global error boundry for sending errors to data dog.
 * This component has to be a class.
 *
 *
 * Production and development builds of React slightly differ in the way
 * componentDidCatch handles errors. In development, the errors will
 * bubble up to window, which means that any window.onerror or
 * window.addEventListener('error', callback) will intercept the errors
 * that have been caught by componentDidCatch. In production, instead,
 * the errors will not bubble up, which means any ancestor error handler
 * will only receive errors not explicitly caught by componentDidCatch.
 * https://react.dev/reference/react/Component#componentdidcatch
 *
 */
export class ErrorBoundary extends React.Component<PropsWithChildren<any>> {
  componentDidCatch(error, errorInfo) {
    const log = datadog.generateLog({
      type: '[React Error Boundary]',
      message: error.message ? error.message : error,
      context: {
        debug: {
          info: errorInfo,
        },
      },
      error,
    });
    datadog.logger.then((logger) => logger.error(...log));
  }

  render() {
    return this.props.children;
  }
}
