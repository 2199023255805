// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

// stores the session id
export const SS_WINDOW_ID_STORAGE_KEY = 'WINDOW_ID';

// stores the search term
export const SS_SEARCH_TERM_KEY = 'SS_SEARCH_TERM';

// stores whether the user was referred by google for
// the analytics controller
export const SS_REFERRED_BY_KEY = 'REFERRED_BY';
