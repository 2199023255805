// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { localStore } from '~/common/utilities/local-store/localStore';

export const localStoreJson = {
  getItem: (key: string) => {
    return JSON.parse(localStore.getItem(key) ?? '{}');
  },
  setItem: (key: string, value: any) => {
    localStore.setItem(key, JSON.stringify(value));
  },
};
