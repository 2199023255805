// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import first from 'lodash/first';
import startCase from 'lodash/startCase';

const getLettersOnly = (message: string) => {
  return startCase(message).replace(/[^a-zA-Z]/g, '');
};

/**
 * Extracts an error type from a message
 * Example: "SyntaxError: Something went wrong" => "SyntaxError"
 * @param message
 * @returns
 */
const getErrorTypeFromMessage = (message: string) => {
  const xErrorPatternMatch = message?.match(/\b([a-zA-Z]*Error)(?=:)/g);
  return first(xErrorPatternMatch);
};

const getFirstSentence = (message: string) => {
  const sentencePattern = /^.+[\.\!\?]+\s/g;
  const sentencePatternMatch = message?.match(sentencePattern);
  const firstSentence = first(sentencePatternMatch);
  return firstSentence;
};

/**
 * Catches errors related to fetching fonts e.g.
 * Example: "XHR error GET https://www.nipht.io/_next/static/media/source-sans-pro-greek-ext-400-normal.bb83722c.woff2" => true
 * @param message
 * @returns
 */
const getIsFontFetchError = (message: string) => {
  return message.startsWith('XHR error GET') && /\.woff\d+$/.test(message);
};

const getKnownErrorKind = (message: string, stack?: string) => {
  if (getIsFontFetchError(message)) {
    return 'XHRFontFetchError';
  }
  return;
};

/**
 * Generates an error kind from a message and stack
 * @param message
 * @param stack
 * @returns
 */
export const generateErrorKind = (message: string, stack?: string) => {
  const errorType = getErrorTypeFromMessage(stack);
  if (errorType) {
    return errorType;
  }

  const knownErrorKind = getKnownErrorKind(message, stack);
  if (knownErrorKind) {
    return knownErrorKind;
  }

  const firstSentence = getFirstSentence(message);
  if (firstSentence) {
    const errorKindCandidate = getLettersOnly(firstSentence);
    if (errorKindCandidate.length >= 3) {
      return errorKindCandidate;
    }
  }
  return getLettersOnly(message);
};
