// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { SIGNUP_UTM_KEY } from '~/common/commonKeys';
import { localStore } from '~/common/utilities/local-store/localStore';
import { getUTMFromWindow } from './get-utm-from-window';
import { UTMData } from './UTMdata';

/**
 * If the user has any non-empty UTM data in the URL, update storage with it.
 * @param currentWindow
 * @param storage
 * @returns true if UTM was updated in local storage
 */
export const storeUTM = (
  currentWindow = window,
  storage: Storage = localStore,
) => {
  if (typeof currentWindow === 'undefined') return;

  const utm = getUTMFromWindow(currentWindow);

  // check if any utm data is not empty
  const hasUTMData = Object.values(utm).some((value) => value != null);
  if (hasUTMData) {
    storage.setItem(SIGNUP_UTM_KEY, JSON.stringify(utm));
    return true;
  }

  return false;
};

export const getStoredUTM = (storage: Storage) => {
  try {
    return JSON.parse(storage.getItem(SIGNUP_UTM_KEY)) as UTMData;
  } catch (e) {
    return null;
  }
};
