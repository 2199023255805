// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import attempt from 'lodash/attempt';
import isError from 'lodash/isError';

export const attemptURL = (url: string | URL, base?: string | URL): URL => {
  if (!url && !base) return undefined;
  const urlAttempt = attempt(() => new URL(url, base));

  if (isError(urlAttempt)) {
    return undefined;
  }

  return urlAttempt;
};
