// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

const seo = {
  defaultTitle: 'Niphtio',
  titleTemplate: '%s | Niphtio',
  description:
    'Save bookmarks and actually remember to read them. No more forgetting to read your bookmarks. With Niphtio, it’s as easy as scrolling through social media.',
  openGraph: {
    type: 'website',
    url: 'https://nipht.io',
    images: [
      {
        url: 'https://www.nipht.io/og-image.png',
        alt: 'Niphtio Logo',
      },
    ],
  },
  twitter: {
    cardType: 'summary',
    site: '@niphtio',
    handle: '@niphtio',
  },
};

export default seo;
