// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { LogsEvent } from '@datadog/browser-logs';
import merge from 'lodash/merge';
import { LogEventModifierProps } from './types';

export const modifyLog = (
  log: LogsEvent,
  modifier: Omit<LogEventModifierProps, 'exclude'>,
) => {
  return merge(log, modifier);
};
