// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import {
  ColorMode,
  ColorModeProviderProps,
  localStorageManager,
} from '@chakra-ui/react';

const setToFixedColorMode = (colorMode: ColorMode) => {
  if (localStorageManager.get() !== colorMode) {
    localStorageManager.set(colorMode);
  }
};

export const FixedColorModeManager = (
  colorMode: ColorMode,
): ColorModeProviderProps['colorModeManager'] => ({
  get() {
    return colorMode;
  },
  set(value) {
    setToFixedColorMode(colorMode);
  },
  type: 'localStorage',
});
