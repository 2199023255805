// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import Cookies from 'universal-cookie';

export const getCookies = {
  fromBrowser: () => {
    return new Cookies(null);
  },
  fromRequestContext: (ctx) => {
    return new Cookies(ctx?.req?.headers?.cookie);
  },
  fromCookieString: (cookieString: string) => {
    return new Cookies(cookieString);
  },
};

export const getCookieKeyValue = (token: string, cookies: string) => {
  const snippet = cookies
    .split(';')
    .map((it) => it.trim())
    .find((it) => it.trim().startsWith(`${token}=`));
  const [key, value] = snippet?.split('=') ?? [];
  return { key, value };
};
