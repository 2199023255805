// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import first from 'lodash/first';
import last from 'lodash/last';
import { COOKIE_SESSION_TOKEN_READABLE } from '~/common/cookieKeys';
import { getCookies } from '~/lib/cookies';

export const getNonHttpAuthCookie = (): string | null | undefined => {
  const cookie = getCookies.fromBrowser().get(COOKIE_SESSION_TOKEN_READABLE);
  return cookie;
};

const isUserIdValid = (candidate: string) => {
  return /^\d+$/.test(candidate);
};

export const getUserIdFromNonHttpAuthCookie = () => {
  const cookie = getNonHttpAuthCookie();
  if (!cookie) {
    return null;
  }
  const parts = cookie.split('|');
  const userId = first(parts);
  if (isUserIdValid(userId)) {
    return userId;
  }
  return undefined;
};

const isValidSessionId = (candidate: string) => {
  return /^[a-f0-9]{32}$/.test(candidate);
};

export const getSessionIdFromNonHttpAuthCookie = () => {
  const cookie = getNonHttpAuthCookie();
  if (!cookie) {
    return null;
  }
  const parts = cookie.split('|');
  const sessionId = last(parts);
  if (isValidSessionId(sessionId)) {
    return sessionId;
  }
  return undefined;
};
