// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import {
  datadogLogs,
  HandlerType,
  LogsInitConfiguration,
  StatusType,
} from '@datadog/browser-logs';
import kebabCase from 'lodash/kebabCase';
import trim from 'lodash/trim';
import {
  DATADOG_CLIENT_TOKEN,
  ENVIRONMENT,
  IS_TEST,
  PROD,
  RELEASE_TAG,
} from '~/common/config/runtime';
import { isBrowser } from '~/common/utilities/browser-utils';
import {
  addFabricatedErrorKindIfMissing,
  addNiphtioAttributes,
  addSessionAttributes,
  addUsrAttributes,
  getIsLogNotDiscardedImpureFn,
} from './utils/getBeforeSend';

export const service: string = 'frontend';
export const env: string = IS_TEST ? 'testing' : ENVIRONMENT;
// @datadog/browser-logs only works in the client browser
// unless we include a window.document polyfill
// if enabled, it will throw ReferenceError: document is not defined
const host: string = isBrowser ? 'client' : 'server';
const loggerName: string = 'datadog-next-logger';
// in development and testing, send logs to 'console'
// otherwise, send logs to 'http' (including staging)
// https://docs.datadoghq.com/logs/log_collection/javascript/#change-the-destination
const handler: HandlerType = PROD && !IS_TEST ? 'http' : 'http';
export const version: string = RELEASE_TAG;

// default context
export const context = {
  service,
  host,
  env,
};

datadogLogs.logger.setHandler(handler);
datadogLogs.logger.setContext(context);

const isLogNotDiscardedImpureFn = getIsLogNotDiscardedImpureFn();

const beforeSend: LogsInitConfiguration['beforeSend'] = (log, context) => {
  addFabricatedErrorKindIfMissing(log);
  addUsrAttributes(log);
  addNiphtioAttributes(log);
  addSessionAttributes(log);
  const isLogNotDiscard = isLogNotDiscardedImpureFn(log, context);
  return isLogNotDiscard;
};

// init datadog
// required before logging
// https://docs.datadoghq.com/logs/log_collection/javascript/#initialization-parameters
const init = () => {
  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    service,
    env,
    version,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: [],
    forwardReports: [],
    sessionSampleRate: 100,
    silentMultipleInit: true,
    beforeSend,
  });
};

const generateLog = (props: {
  type: string;
  message?: string;
  context?: object;
  error?: Error;
}): [string, object, Error] => {
  const logGroup = kebabCase(props.type);
  let msg = '';
  if (props.type) {
    msg += `${props.type}: `;
  }
  if (props.message) {
    const trimmed = trim(props.message);
    msg += `${trimmed}`;
    // add . to end of sentence
    if (!trimmed.endsWith('.')) {
      msg += '. ';
    } else {
      msg += ' ';
    }
  }

  return [
    msg,
    {
      ...props.context,
      niphtio: {
        ...props.context?.['niphtio'],
        log_group: logGroup,
      },
    },
    props.error,
  ];
};

interface ProxyLogger {
  log(
    message: string,
    messageContext?: object,
    status?: StatusType,
    error?: Error,
  ): void;
  debug(message: string, messageContext?: object, error?: Error): void;
  info(message: string, messageContext?: object, error?: Error): void;
  warn(message: string, messageContext?: object, error?: Error): void;
  error(message: string, messageContext?: object, error?: Error): void;
}

const proxyHandler = {
  get(target, prop, receiver) {
    // override methods to reuse context
    if (['log'].includes(prop)) {
      return (
        message: string,
        messageContext?: object,
        status?: StatusType,
        error?: Error,
      ) => {
        const overrideMessageContext = {
          ...context,
          ...messageContext,
        };
        target[prop].apply(target, [
          message,
          overrideMessageContext,
          status,
          error,
        ]);
      };
    }
    if (['debug', 'info', 'warn', 'error'].includes(prop)) {
      return (message: string, messageContext?: object, error?: Error) => {
        const overrideMessageContext = {
          ...context,
          ...messageContext,
        };
        target[prop].apply(target, [message, overrideMessageContext, error]);
      };
    }
    return target[prop];
  },
};

export const datadog = {
  init,
  generateLog,
  get logger(): Promise<ProxyLogger> {
    return new Promise((resolve, reject) => {
      // @datadog/browser-logs only works from client
      // unless we include a window.document polyfill
      // if enabled, it will throw ReferenceError: document is not defined
      if (!isBrowser) {
        const proxy = new Proxy(console, proxyHandler);
        resolve(proxy);
        return;
      }

      datadogLogs.onReady(() => {
        const _logger =
          datadogLogs.getLogger(loggerName) ??
          datadogLogs.createLogger(loggerName, {
            context,
            handler,
          });

        const proxy = new Proxy(_logger, proxyHandler);
        resolve(proxy);
      });
    });
  },
};

if (!PROD) {
  global.datadog = datadog;
}
