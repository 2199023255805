// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import { Global } from '@emotion/react';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import { theme as customTheme } from '@niphtio/np-theme';
import 'intersection-observer';
import omit from 'lodash/omit';
import { DefaultSeo } from 'next-seo';
import Head from 'next/head';
import Script from 'next/script';
import { useEffect } from 'react';
import { DEV } from '~/common/config/runtime';
import seo from '~/common/config/seo';
import { isBrowser } from '~/common/utilities/browser-utils';
import { cookieStore } from '~/common/utilities/cookie-utils/cookieStore';
import { localStore } from '~/common/utilities/local-store/localStore';
import { storeReferrer } from '~/common/utilities/tracking-utils/referrer/store-referrer';
import { storeUTM } from '~/common/utilities/tracking-utils/utm/store-utm';
import { ErrorBoundary } from '~/components/ErrorBoundry';
import { NpThemeProvider } from '~/containers/common/Item/NpThemeProvider';
import { globalStyles } from '~/containers/common/Styles/globalStyles';
import { useGoogleAnalyticsController } from '~/hooks/useGoogleAnalyticsController';
import { datadog } from '~/lib/datadog/datadog-logger';
import { rumInit } from '~/lib/datadog/datadog-rum';

const App = ({ Component, pageProps }) => {
  const { isGoogleAnalyticsAllowed } = useGoogleAnalyticsController();

  useEffect(() => {
    datadog.init();
    rumInit();
    storeUTM(window, cookieStore);
    storeUTM(window, localStore);
    storeReferrer(window, cookieStore);
    storeReferrer(window, localStore);
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />
      </Head>
      {isGoogleAnalyticsAllowed && (
        <Script src="/google-tag-manager-init-script.js" />
      )}
      <DefaultSeo {...omit(seo, 'titleTemplate')} />
      <ErrorBoundary>
        <NpThemeProvider
          isColorModeSupported={pageProps.isColorModeSupported}
          colorMode={pageProps.colorMode}
        >
          <Global styles={globalStyles} />
          <Component {...pageProps} />
        </NpThemeProvider>
      </ErrorBoundary>
    </>
  );
};

if (DEV && isBrowser) {
  (window as any).appTheme = customTheme;
}

export default App;
