// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import parser, { UAParser } from 'ua-parser-js';
import { LS_USD_BROWSER_SUPPORT_WARNING_DIMISSED_KEY } from './local-store/localStorageKeys';
import { localStoreJson } from './local-store/localStoreJson';

const SUPPORTED_BROWSERS = [
  'Mobile Safari',
  'Safari',
  'Chrome',
  'Firefox',
  'Edge',
  'WebKit',
];

export const setBrowserSupportDimissed = () => {
  localStoreJson.setItem(LS_USD_BROWSER_SUPPORT_WARNING_DIMISSED_KEY, true);
};

export const isBrowserSupportModalDismissed = () => {
  return (
    localStoreJson.getItem(LS_USD_BROWSER_SUPPORT_WARNING_DIMISSED_KEY) === true
  );
};

export const isBrowserSupported = (currentWindow: Window) => {
  const ua = parser(currentWindow.navigator.userAgent);

  return SUPPORTED_BROWSERS.includes(ua.browser.name);
};

/**
 * Indicate if the current environment is SSR or client side
 */
export const isBrowser = typeof window !== 'undefined';

export const isAndroidOs = () => {
  const parser = new UAParser();
  return (
    isBrowser && parser.getOS().name.toLocaleLowerCase().includes('android')
  );
};
