// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

// http only cookie for the server
export const COOKIE_SESSION_TOKEN = 'niphtio';
// non http only cookie to indicate user is logged in
// related to [COOKIE_SESSION_TOKEN]
export const COOKIE_SESSION_TOKEN_READABLE = 'niphtio_';

export const COOKIE_COLOR_MODE_TOKEN = 'color_mode';
