// Copyright © 2023 Niphtio, Inc.

import { SS_REFERRED_BY_KEY } from '~/common/utilities/session-store/sessionStorageKeys';

// types
export const GOOGLE_KEY = 'google';

/**
 * Convenince function for storing whether user was lead to the site
 * by google or google ads.
 *
 * @param storage
 * @param key Available to modify for testing purposes.
 * @returns
 */
export const setReferredByGoogle = (
  storage: Storage,
  key: string = SS_REFERRED_BY_KEY,
) => {
  storage.setItem(key, GOOGLE_KEY);

  return storage.getItem(key) === GOOGLE_KEY;
};

/**
 * Convenince function for getting whether user was lead to the site
 * by google or google ads.
 *
 * @param storage sessionStorage
 * @param key Available to modify for testing purposes.
 * @returns
 */
export const getReferredByGoogle = (
  storage: Storage,
  key: string = SS_REFERRED_BY_KEY,
) => {
  return storage.getItem(key) === GOOGLE_KEY;
};
