// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { UTMData } from './UTMdata';

/**
 *
 * @param url
 * @returns
 */
export const getUTM = (url?: URL): UTMData => {
  const utmSource = url?.searchParams?.get('utm_source');
  const utmMedium = url?.searchParams?.get('utm_medium');
  const utmCampaign = url?.searchParams?.get('utm_campaign');
  const utmTerm = url?.searchParams?.get('utm_term');
  const utmContent = url?.searchParams?.get('utm_content');

  return {
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
  };
};
