// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { LogsEvent, LogsInitConfiguration } from '@datadog/browser-logs';
import { isBrowser } from '~/common/utilities/browser-utils';
import {
  getSessionIdFromNonHttpAuthCookie,
  getUserIdFromNonHttpAuthCookie,
} from '~/common/utilities/cookie-utils/nonHttpAuthCookie';
import { logWatchlist } from '../datadog-log-watchlist';
import { generateErrorKind } from './generateErrorKind';
import { getIsMatch } from './getIsMatch';
import { modifyLog } from './modifyLog';
import { LogEventInterceptorProps } from './types';

/**
 * Modifies log and returns false if the log should be discarded.
 *
 * @param watchlist list of logs to modify
 * @returns false if log should be discarded, true otherwise
 */
export const getIsLogNotDiscardedImpureFn = (
  watchlist: LogEventInterceptorProps[] = logWatchlist,
): LogsInitConfiguration['beforeSend'] => {
  return (log) => {
    for (const item of watchlist) {
      const isMatch = getIsMatch(log, item.identify);
      if (!isMatch) {
        continue;
      }
      const { exclude, ...props } = item.modify;
      if (exclude) {
        return false;
      }
      log = modifyLog(log, props);
    }
    return true;
  };
};

/**
 * If a log with an error status does not contain an error kind
 * then this function adds a kind and valid stack as needed.
 * https://docs.datadoghq.com/logs/error_tracking/backend/?tab=serilog#attributes-for-error-tracking
 * @param log
 * @returns N/A
 */
export const addFabricatedErrorKindIfMissing = (log: LogsEvent) => {
  if (log.status !== 'error') return;
  if (log.error?.kind) return;

  const errorKind = generateErrorKind(log.message, log.error?.stack);
  log.error = {
    ...log.error,
    kind: errorKind,
    isKindFabricated: true,
  };
};

export const addUsrAttributes = (log: LogsEvent) => {
  const userId = isBrowser ? getUserIdFromNonHttpAuthCookie() : null;
  const usr = {
    id: userId,
  };
  log.usr = usr;
  return;
};

export const addNiphtioAttributes = (log: LogsEvent) => {
  const sessionId = isBrowser ? getSessionIdFromNonHttpAuthCookie() : null;
  const current = (log?.['niphtio'] as any) ?? {};
  const niphtio = {
    ...current,
    session: {
      id: sessionId,
    },
  };
  log.niphtio = niphtio;
  return;
};

export const addSessionAttributes = (log: LogsEvent) => {
  // datadog synthetic user
  const isSyntheticUser =
    isBrowser && window['_DATADOG_SYNTHETICS_BROWSER'] == true;
  const sessionType = isSyntheticUser ? 'synthetics' : 'user';
  const current = (log?.['session'] as any) ?? {};
  const session = {
    ...current,
    type: sessionType,
  };
  log.session = session;
};
