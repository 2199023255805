// Copyright © 2020 Niphtio, Inc.

/**
 * This is a great spot to throw in application-wide styles
 */
export const globalStyles = `

  // Toasts at bottom should appear above
  // the bottom menu bar which occurs at less than 48em
  @media screen and (max-width: 48em) {
    #chakra-toast-manager-bottom {
      bottom: 60px !important;
    }
  }

  body {
    // env(safe-area-inset-*) accounts for edges on ios devices
    // See: https://webkit.org/blog/7929/designing-websites-for-iphone-x/
    padding-top: env(safe-area-inset-top);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
  }
  
  // <-- Layout shift workaround -->
  @media (hover: hover) {
    body {
      // Prevents layout shift due to scrollbar appearing
      // only when content is scrollable
      margin-right: calc(100% - 100vw);
      // Prevents content from being under scrollbar due to
      // negative margin workaround seen above.
      // !important prevents portal styles e.g. modal
      // from removing padding and causing layout shift
      // --
      // env(safe-area-inset-right) accounts for edges on ios devices
      // See: https://webkit.org/blog/7929/designing-websites-for-iphone-x/
      padding-right: calc(env(safe-area-inset-right) + 16px)!important;
    }
  }
  // <-- ./Layout shift workaround -->
`;
