// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import getConfig from 'next/config';
const { publicRuntimeConfig: PRC } = getConfig();

export const DATADOG_CLIENT_TOKEN: string = PRC.DATADOG_CLIENT_TOKEN;
export const DATADOG_RUM_CLIENT_TOKEN: string = PRC.DATADOG_RUM_CLIENT_TOKEN;
export const ENVIRONMENT: string = PRC.ENVIRONMENT;
export const DEV: boolean = PRC.DEVELOPMENT;
export const PROD: boolean = PRC.PRODUCTION;
export const STAGING: boolean = PRC.STAGING;
export const RELEASE_TAG: string = process.env.PUBLISH_TAG;
export const GQL_URL: string = PRC.GQL_URL;
export const WS_GQL_URL: string = PRC.WS_GQL_URL;
export const DATADOG_RUM_APP_ID: string = PRC.DATADOG_RUM_APP_ID;
export const NIPHTIO_CHROME_EXTENSION_ID: string =
  PRC.NIPHTIO_CHROME_EXTENSION_ID;
export const IS_REVIEW_APP: string = PRC.IS_REVIEW_APP;
export const IS_TEST: string = PRC.IS_TEST;
