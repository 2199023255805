// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { LogsEvent } from '@datadog/browser-logs';
import eq from 'lodash/eq';
import isEmpty from 'lodash/isEmpty';
import isMatch from 'lodash/isMatch';
import isMatchWith from 'lodash/isMatchWith';
import isObject from 'lodash/isObject';
import { LogEventIdentityProps } from './types';

const isKVpair = (obj: any) => {
  try {
    return isObject(obj) && !isEmpty(Object.keys(obj));
  } catch (e) {
    return false;
  }
};

export const getIsMatch = (log: LogsEvent, identify: LogEventIdentityProps) => {
  const comparer = (value, src) => {
    const match =
      typeof src === 'function'
        ? src(value)
        : isKVpair(value)
          ? isMatchWith(value, src, comparer)
          : Object(value) !== value // if primitive
            ? eq(value, src)
            : isMatch(value, src);
    return match;
  };
  return isMatchWith(log, identify, comparer);
};
