// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { ColorMode, ColorModeProviderProps } from '@chakra-ui/react';
import { COOKIE_COLOR_MODE_TOKEN } from '~/common/cookieKeys';
import { getCookies } from '~/lib/cookies';

const daysFromNow = (days: number) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const date = today.getDate();
  const future = new Date(year, month, date + days);
  return future;
};

export const CookieColorModeManager = (
  colorMode: ColorMode,
): ColorModeProviderProps['colorModeManager'] => ({
  get() {
    return colorMode;
  },
  set(value) {
    getCookies.fromCookieString(null).set(COOKIE_COLOR_MODE_TOKEN, value, {
      path: '/',
      sameSite: 'lax',
      expires: daysFromNow(400), // 400 is the max lifetime https://httpwg.org/http-extensions/draft-ietf-httpbis-rfc6265bis.html#name-the-expires-attribute
    });
  },
  type: 'cookie',
});
