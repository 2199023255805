// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { COOKIE_SESSION_TOKEN } from '~/common/cookieKeys';
import { isBrowser } from '~/common/utilities/browser-utils';
import { getNonHttpAuthCookie } from '~/common/utilities/cookie-utils/nonHttpAuthCookie';
import {
  getReferredByGoogle,
  setReferredByGoogle,
} from '~/common/utilities/session-store/getReferredBy';
import { localSessionStore } from '~/common/utilities/session-store/localSessionStore';
import { getUTMFromWindow } from '~/common/utilities/tracking-utils/utm/get-utm-from-window';
import { getCookieKeyValue } from '~/lib/cookies';

const isUserSignedInFn = () => {
  if (!isBrowser) return false;

  const authCookieExists =
    !isEmpty(getCookieKeyValue(COOKIE_SESSION_TOKEN, document.cookie).value) ||
    !isEmpty(getNonHttpAuthCookie());
  return authCookieExists;
};

export const useGoogleAnalyticsController = () => {
  const router = useRouter();
  const [isReferredByGoogle, setIsReferredByGoogle] = useState(false);
  const [isPageAllowed, setIsPageAllowed] = useState(false);
  const isUserSignedIn = isUserSignedInFn();

  const isGoogleAnalyticsAllowed =
    isReferredByGoogle && isPageAllowed && !isUserSignedIn;

  useEffect(() => {
    const isAppPage = router.asPath.startsWith('/app');
    setIsPageAllowed(!isAppPage);
  }, [router.asPath]);

  useEffect(() => {
    const isReferredByGoogleFn = () => {
      const referredByGoogle = getReferredByGoogle(localSessionStore);
      if (referredByGoogle) return true;

      const utm = getUTMFromWindow(window);
      const isGoogleUTM = Object.values(utm).some((it) =>
        it?.includes('google-ads'),
      );
      if (isGoogleUTM) {
        setReferredByGoogle(localSessionStore);
        return true;
      }
      const referrer = window?.document?.referrer;
      const isGoogleReferrer = referrer.includes('.google.');
      if (isGoogleReferrer) {
        setReferredByGoogle(localSessionStore);
        return true;
      }
      return false;
    };

    // only allow ga scripts if the user was referred by google
    setIsReferredByGoogle(isReferredByGoogleFn());
  }, [router.asPath]);

  return {
    isGoogleAnalyticsAllowed,
  };
};
