// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

/**
 * Store in localstorage or session store if available
 * otherwise store in a local object
 */
export class CustomStorage implements Storage {
  private store: Storage;
  private available: boolean;
  length = 0;
  [name: string]: any;
  private keys = [];
  constructor(type: 'localStorage' | 'sessionStorage', internal = false) {
    this.available = internal ? false : this.storageAvailable(type);

    if (this.available) {
      this.store = window[type];
    }
  }

  private storageAvailable(type) {
    let storage;
    try {
      storage = window[type];
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  }

  public clear() {
    if (this.available) {
      this.store.clear();
    }
    this.keys.map((key) => {
      delete this[key];
    });
    this.keys = [];
    this.length = 0;
  }

  public key(index: number): string | null {
    if (this.available) {
      return this.store.key(index);
    } else {
      return this.keys[index] ?? null;
    }
  }

  public removeItem(key: string): void {
    if (this.available) {
      this.store.removeItem(key);
      this.length = this.store.length;
    } else {
      delete this[key];
      this.length--;
      const index = this.keys.indexOf(key);
      if (index != -1) {
        this.keys.splice(index, 1);
      }
    }
  }

  public getItem(key: string) {
    if (this.available) {
      return this.store.getItem(key);
    } else {
      return this[key] ?? null;
    }
  }

  public setItem(key: string, value: any) {
    if (this.available) {
      this.store.setItem(key, value);
      this.length = this.store.length;
    } else {
      this[key] = value;
      this.keys.push(key);
      this.length++;
    }
  }
}
