// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import isEmpty from 'lodash/isEmpty';
import { SIGNUP_REFERRER_KEY } from '~/common/commonKeys';

/**
 * If the user has any non-empty referer header and it isn't from niphtio, update storage with it.
 * @param currentWindow
 * @param storage
 * @returns true if referer in local storage is updated
 */
export const storeReferrer = (currentWindow: Window, storage: Storage) => {
  if (typeof currentWindow === 'undefined') return;

  const referer = currentWindow?.document?.referrer;

  // check if any referer exists
  const isReferrerEmpty = isEmpty(referer);
  // check if referer is within the site
  const isReferrerNiphtio = (referer ?? '').startsWith('https://www.nipht.io/');
  if (!isReferrerEmpty && !isReferrerNiphtio) {
    storage.setItem(SIGNUP_REFERRER_KEY, referer);
    return true;
  }

  return false;
};

export const getStoredReferrer = (storage: Storage) => {
  return storage.getItem(SIGNUP_REFERRER_KEY);
};
