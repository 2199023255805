// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { attemptURL } from '~/common/utilities/url-utils/attemptURL';
import { getUTM } from './get-utm';
import { UTMData } from './UTMdata';

/**
 * Get UTM data from the currentWindow.
 * @param currentWindow
 */
export const getUTMFromWindow = (currentWindow = window): UTMData => {
  if (typeof currentWindow === 'undefined')
    return {
      utmSource: null,
      utmMedium: null,
      utmCampaign: null,
      utmTerm: null,
      utmContent: null,
    };
  const srcUrl = currentWindow?.location?.href;
  const url = attemptURL(srcUrl);
  return getUTM(url);
};
